import { gql } from "@apollo/client";

export const REQUEST_QUOTE_MUTATION = gql`
mutation CreateRequestAQuote($data: RequestAQuoteInput!) {
  createRequestAQuote(data: $data) {
    data {
      id
    }
  }
}
`;
