import { CREATE_JOB_APPLICATION_MUTATION } from "./JobApplication/apply";
import { CREATE_CONTACT_FORM_MUTATION } from "./ContactUs/contactUs";
import { UPLOAD_FILE_MUTATION } from "./Upload/file";
import { REQUEST_QUOTE_MUTATION } from "./GetAQuote/GetAQuote";

export {
  CREATE_JOB_APPLICATION_MUTATION,
  CREATE_CONTACT_FORM_MUTATION,
  UPLOAD_FILE_MUTATION,
  REQUEST_QUOTE_MUTATION,
};
