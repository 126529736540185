import { gql } from "@apollo/client";

export const CREATE_CONTACT_FORM_MUTATION = gql`
  mutation CreateContactFromList($data: ContactFromListInput!) {
    createContactFromList(data: $data) {
      data {
        id
      }
    }
  }
`;
