import { ApolloProvider } from "@apollo/client";
import client from "@/apollo-client";
import { ChakraProvider } from "@chakra-ui/react";
import Layout from "@/components/Layout";
import "../styles/globals.css";
import theme from "@/theme";
import * as gtag from "@/lib/gtag";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import Script from "next/script";
import { Rubik } from "next/font/google";
import GacModal from "@/components/Modal/modal";

const rubik = Rubik({
  weight: ["400", "500", "600", "700", "800"],
  style: ["normal", "italic"],
  subsets: ["latin"],
});

export default function App({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      {/* <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtag.GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      </Head> */}
      <Script
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtag.GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
        }}
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <ChakraProvider theme={theme}>
        <main className={rubik.className}>
          <CookiesProvider defaultSetOptions={{ path: "/" }}>
            <ApolloProvider client={client}>
              <GacModal />
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </ApolloProvider>
          </CookiesProvider>
        </main>
      </ChakraProvider>
    </>
  );
}
