import { gql } from "@apollo/client";

export const CREATE_JOB_APPLICATION_MUTATION = gql`
  mutation CreateJobApplication($data: JobApplicationInput!) {
    createJobApplication(data: $data) {
      data {
        id
      }
    }
  }
`;
