import {
  Flex,
  Text,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  ModalFooter,
  Box,
  Heading,
  IconButton,
  Alert,
  AlertIcon,
  Container,
} from "@chakra-ui/react";
import { MdOutlineEmail } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { CgOrganisation } from "react-icons/cg";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CONTACT_PAGE_QUERY } from "@/apollo/queries";
import { REQUEST_QUOTE_MUTATION } from "@/apollo/mutations";
import { useMutation, useQuery } from "@apollo/client";

import Loader from "@/components/Loader";
import { useCookies } from "react-cookie";
import Link from "next/link";

const initState = {
  email: "",
  full_name: "",
  requirement: "",
  organization: "",
  phone: "",
};

export default function contact() {
  const recaptchaRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputs, setInputs] = useState(initState);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmiited, setIsSubmitted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [captchaError, setCaptchaError] = useState(null);
  const [handleAlert, sethandleAlert] = useState();
  const [cookies, setCookies, removeCookie] = useCookies([]);
  const [showMobileMessage, setShowMobileMessage] = useState(false);
  const [shouldRender, setShouldRender] = useState(true);
  const [options, setoptions] = useState({
    path: "/",
    expires: new Date(Date.now() + 60 * 1000 * 60 * 24 * 31),
  });
  const {
    isOpen: isVisible,
    onClose: alertClose,
    onOpen: alertOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const { loading, error, data } = useQuery(CONTACT_PAGE_QUERY);
  const [
    createRequestaQuote,
    { loading: contactLoading, data: contactData, error: contactError },
  ] = useMutation(REQUEST_QUOTE_MUTATION, {
    onCompleted: (data) => {
      // console.log("Data created successfully", data);
    },
    onError: (error) => {
      // setShouldRender(true)
      console.error("Error creating data", error);
      sethandleAlert(error);
    },
  });
  // console.log("contactDAta", contactData);
  // console.log("contanctError", contactError);
  useEffect(() => {
    if (contactData) {
      setShowAlert(true);
      setShowMobileMessage(true);
      setIsSubmitted(false);
      setTimeout(() => {
        setShowAlert(false);
        onClose();
        setShouldRender(false);
      }, 7000);
    } else if (contactError) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        onClose();
        setShouldRender(false);
      }, 7000);
    }
    if (isVisible) {
      removeAlert();
    }
    const timer = setTimeout(() => {
      onOpen();
    }, 20000);

    return () => clearTimeout(timer);
  }, [contactData, isVisible]);
  useEffect(() => {
    if (cookies.dataFromModel) {
      setShouldRender(false);
    }
  }, []);
  if (!shouldRender) return null;

  if (loading) {
    return <Loader />;
  }

  if (error || contactError) {
    // console.error(error && error);
    // console.error(contactError && contactError);
    return null;
  }

  const removeAlert = () => {
    alertClose();
  };

  const handleChange = ({ target: { name, value } }) => {
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitted(true);

      // Check phone number 8 digits se upper hai ya nahi
      if (inputs.phone.length < 8) {
        setCaptchaError(
          "Please update your phone number as it appears to be invalid."
        );
        return;
      }

      setCookies("dataFromModel", inputs, options);

      createRequestaQuote({
        variables: {
          data: inputs,
        },
      }).then(({ errors }) => {
        if (!errors) {
          // console.log("fssggsggs");
          // setIsSubmitted(false);
        } else if (errors) {
          sethandleAlert(errors);
          // console.log("submitingError", errors);
          removeCookie("dataFromModel");
        }
      });
      if (!contactLoading) {
        alertOpen();
      }
    } catch (error) {
      isOpen();
      handleAlert(error);
    } finally {
      setIsDisabled(true);
      setInputs(initState);
      recaptchaRef.current.reset();
    }
  };

  const { Title, Description, MainSec, locations, social_links, SEO } =
    data.contactPage.data.attributes;
  const onReCAPTCHAChange = async (token) => {
    try {
      if (!token) {
        setCaptchaError("captcha verification invalid or expired.");
        return;
      } else {
        const response = await fetch("/api/verifyCaptchaToken", {
          method: "POST",
          body: JSON.stringify({ captcha: token }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          setIsDisabled(false);
        } else {
          const error = await response.json();
          // throw new Error(error.requirement);
          // console.error(error.requirement);
          setCaptchaError(error.requirement);
        }
      }
    } catch (error) {
      // console.error(error);
      setCaptchaError("something went wrong.");
    }
  };
  const handleCancel = () => {
    const data = { cancel: "User Cancel The Filling Request a Quote Value" };
    setCookies("dataFromModel", data, options);
    onClose();
  };
  // console.log("handleAlert", handleAlert);
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleCancel}
        size={{ base: "auto", md: "2xl", lg: "5xl" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent background={"white"}>
          <ModalHeader
            textAlign={"center"}
            pt={{ base: 3 }}
            display={{ base: "block", lg: "none" }}
          >
            Request a Quote
          </ModalHeader>
          <ModalCloseButton onClick={handleCancel} />
          <ModalBody
            className="test12"
            pb={{ base: 0, md: 1 }}
            maxH={{ base: "80vh", md: "fit-content" }}
            overflowY={{ base: "auto", md: "auto" }}
            padding={{ base: 2 }}
          >
            <Box
              px={{ base: 0, md: 8, xl: 5 }}
              pt={
                showMobileMessage
                  ? { base: 11, md: 0, lg: 12 }
                  : { base: 0, md: 0, lg: 11 }
              }
            >
              <Heading
                my={{ base: "-1px" }}
                textAlign={"center"}
                p={0}
                pb={6}
                display={{ base: "none", lg: "block" }}
              >
                Request a Quote
              </Heading>
              <Container
                className="test1"
                as={Container}
                maxW="7xl"
                centerContent
                overflow="hidden"
                p={0}
              >
                <Flex
                  flexWrap={{ base: "wrap", lg: "nowrap" }}
                  w={"100%"}
                  flexDirection={{ base: "column", lg: "row" }}
                >
                  <Box
                    borderRadius="lg"
                    rounded="lg"
                    borderColor={"white.20"}
                    borderWidth={{ base: "inherit", md: "2px" }}
                    mb={{ base: 1, md: 8 }}
                    w={"100%"}
                  >
                    <Box
                      py={{ base: 0, md: 5, lg: 5 }}
                      px={{ base: 0, md: 5 }}
                      display={"flex"}
                      gap={4}
                      flexWrap={{ base: "wrap", sm: "wrap", md: "nowrap" }}
                      w={"100%"}
                      alignItems={{ base: "unset", lg: "flex-start" }}
                    >
                      <Flex
                        bg={
                          "linear-gradient(135deg, hsl(0deg 40% 95%) 0%, hsl(208deg 93% 87%) 100%, hsl(32deg 97% 59%) 100%, hsl(0deg 0% 100%) 100%)"
                        }
                        rounded="lg"
                        p={5}
                        w={{ base: "100%", lg: "40%" }}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        marginBottom={"5em"}
                        h={"442px"}
                        display={{ base: "none", lg: "block" }}
                      >
                        <Heading
                          fontSize={{ base: "2xl", xl: "3xl" }}
                          mb={"5"}
                          fontWeight={"medium"}
                          color={"blue.10"}
                        >
                          {/* {Title} */}
                        </Heading>
                        <Text
                          fontSize={{ base: "sm", xl: "md" }}
                          color="gray.10"
                        >
                          {Description}
                        </Text>

                        {/* Social Icons Start*/}
                        <Box pt={8}>
                          <VStack pl={0} spacing={3} alignItems="flex-start">
                            {social_links.data.map((s, index) => {
                              const { path, viewbox, Name, Fill } =
                                s.attributes.Icon;
                              return (
                                <Link
                                  href={s.attributes.url}
                                  style={{ textDecoration: "none" }}
                                >
                                  <Flex
                                    alignItems={"center"}
                                    fontWeight={"500"}
                                    color={"blue.10"}
                                    _hover={{
                                      button: {
                                        bg: "transparent",
                                        color: "orange.10",
                                      },
                                      p: { color: "orange.10" },
                                    }}
                                  >
                                    <IconButton
                                      aria-label={Name}
                                      mr={"2"}
                                      variant="ghost"
                                      size="md"
                                      color={"orange.10"}
                                      isRound={true}
                                      icon={
                                        <svg
                                          width="24"
                                          height="24"
                                          className="chakra-icon"
                                          viewBox={viewbox || "0 0 24 24"}
                                          fill={Fill}
                                          dangerouslySetInnerHTML={{
                                            __html: path,
                                          }}
                                        />
                                      }
                                      _hover={{
                                        button: { bg: "transparent" },
                                      }}
                                    />
                                    <Text fontSize={{ base: "sm", xl: "md" }}>
                                      {s.attributes.value}
                                    </Text>
                                  </Flex>
                                </Link>
                              );
                            })}
                          </VStack>
                        </Box>
                        {/* Social Icons End */}

                        {/* Locations Start */}
                        <Box pt={5}>
                          <VStack pl={0} spacing={3} alignItems="flex-start">
                            {locations.data.map((s, index) => {
                              const { path, viewbox, Name, Fill } =
                                s.attributes.SVG;
                              return (
                                <Flex alignItems={"center"}>
                                  <IconButton
                                    aria-label={Name}
                                    mr={"2"}
                                    variant="ghost"
                                    size="md"
                                    color={"orange.10"}
                                    isRound={true}
                                    icon={
                                      <svg
                                        width="24"
                                        height="24"
                                        className="chakra-icon"
                                        viewBox={viewbox || "0 0 24 24"}
                                        fill={Fill}
                                        dangerouslySetInnerHTML={{
                                          __html: path,
                                        }}
                                      />
                                    }
                                    _hover={{
                                      button: {
                                        bg: "transparent",
                                        color: "orange.10",
                                      },
                                    }}
                                  />
                                  <Text
                                    fontSize={{ base: "sm", xl: "md" }}
                                    color={"gray.10"}
                                  >
                                    {s.attributes.Address}
                                  </Text>
                                </Flex>
                              );
                            })}
                          </VStack>
                        </Box>
                        {/* Locations End */}
                      </Flex>

                      <Box
                        bg="white"
                        borderRadius="lg"
                        w={{ base: "100%", lg: "60%" }}
                      >
                        {showAlert && (
                          <Alert
                            display={{ base: "inline-block", lg: "none" }}
                            status="success"
                            variant="left-accent"
                          >
                            <AlertIcon />
                            Thanks for submitting Request a Quote form. Our team
                            will get back to you soon.
                          </Alert>
                        )}
                        <Box
                          m={0}
                          color="#0B0E3F"
                          display={
                            showMobileMessage
                              ? { base: "none", lg: "block" }
                              : null
                          }
                        >
                          <form onSubmit={handleSubmit}>
                            <VStack
                              style={{ marginBottom: "auto" }}
                              spacing={{ base: "1", lg: "14px" }}
                            >
                              <Flex
                                w={"100%"}
                                gap={{ base: "2", md: 1, lg: "4" }}
                                flexWrap={{ base: "wrap", lg: "nowrap" }}
                              >
                                <FormControl id="name" isRequired>
                                  <FormLabel
                                    fontSize={"md"}
                                    fontWeight={"normal"}
                                    color={"blue.10"}
                                  >
                                    Full Name
                                  </FormLabel>
                                  <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement
                                      pointerEvents="none"
                                      height={"45px"}
                                      children={<BsPerson color="gray.800" />}
                                    />
                                    <Input
                                      type="text"
                                      name="full_name"
                                      size="lg"
                                      value={inputs.full_name}
                                      onChange={handleChange}
                                    />
                                  </InputGroup>
                                </FormControl>
                                <FormControl id="org">
                                  <FormLabel
                                    fontSize={"md"}
                                    fontWeight={"normal"}
                                    color={"blue.10"}
                                  >
                                    Organization
                                  </FormLabel>
                                  <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement
                                      pointerEvents="none"
                                      height={"45px"}
                                      children={
                                        <CgOrganisation color="gray.800" />
                                      }
                                    />
                                    <Input
                                      type="text"
                                      name="organization"
                                      size="lg"
                                      value={inputs.organization}
                                      onChange={handleChange}
                                    />
                                  </InputGroup>
                                </FormControl>
                              </Flex>
                              <Flex
                                w={"100%"}
                                gap={{ base: "2", lg: "4" }}
                                flexWrap={{ base: "wrap", lg: "nowrap" }}
                              >
                                <FormControl id="email" mr={"2"} isRequired>
                                  <FormLabel
                                    fontSize={"md"}
                                    fontWeight={"normal"}
                                    color={"blue.10"}
                                  >
                                    E-Mail
                                  </FormLabel>
                                  <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement
                                      pointerEvents="none"
                                      height={"45px"}
                                      children={
                                        <MdOutlineEmail color="gray.800" />
                                      }
                                    />
                                    <Input
                                      type="email"
                                      name="email"
                                      size="lg"
                                      value={inputs.email}
                                      onChange={handleChange}
                                    />
                                  </InputGroup>
                                </FormControl>
                                <FormControl id="phone" isRequired>
                                  <FormLabel
                                    fontSize={"md"}
                                    fontWeight={"normal"}
                                    color={"blue.10"}
                                  >
                                    Phone
                                  </FormLabel>
                                  <PhoneInput
                                    className="phone_input"
                                    inputProps={{
                                      name: "phone",
                                      required: true,
                                    }}
                                    country={"in"}
                                    value={inputs.phone}
                                    onChange={(value) =>
                                      handleChange({
                                        target: { name: "phone", value },
                                      })
                                    }
                                  />
                                </FormControl>
                              </Flex>
                              <FormControl
                                id="requirement"
                                isRequired
                                pb={{ base: 1 }}
                              >
                                <Flex>
                                  <FormLabel
                                    fontSize={"md"}
                                    fontWeight={"normal"}
                                    color={"blue.10"}
                                  >
                                    Requirement
                                  </FormLabel>{" "}
                                </Flex>
                                <Textarea
                                  name="requirement"
                                  borderColor="gray.300"
                                  _hover={{
                                    borderRadius: "gray.300",
                                  }}
                                  value={inputs.requirement}
                                  onChange={handleChange}
                                  rows={4}
                                  height={{ base: "75px", lg: "123px" }}
                                />
                              </FormControl>

                              {/* reCAPTCHA verification */}
                              <ReCAPTCHA
                                style={{ alignSelf: "baseline" }}
                                ref={recaptchaRef}
                                size="normal"
                                sitekey={
                                  process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY
                                }
                                onChange={onReCAPTCHAChange}
                              />

                              {captchaError && (
                                <Text color={"red.600"}>{captchaError}</Text>
                              )}
                              <FormControl
                                textAlign={"center"}
                                id="btnSubmit"
                                display={{
                                  base: "none",
                                  md: "flex",
                                  lg: "flex",
                                }}
                                justifyContent={{
                                  base: "center",
                                  md: "flex-start",
                                }}
                                pt={{ base: 2, lg: 0 }}
                              >
                                <Button
                                  type="submit"
                                  colorScheme="blue"
                                  mr={3}
                                  id="apply_btn"
                                  isLoading={isSubmiited}
                                  loadingText="Submitting"
                                  isDisabled={isDisabled}
                                >
                                  Submit
                                </Button>

                                <Button onClick={handleCancel}>Cancel</Button>
                              </FormControl>
                            </VStack>
                          </form>
                        </Box>
                      </Box>
                    </Box>
                    {showAlert && (
                      <Alert
                        display={{ base: "none", lg: "inline-block" }}
                        status="success"
                        variant="left-accent"
                      >
                        <AlertIcon />
                        Thanks for submitting Request a Quote form. Our team
                        will get back to you soon.
                      </Alert>
                    )}{" "}
                  </Box>
                </Flex>
              </Container>
            </Box>
          </ModalBody>

          <ModalFooter
            // py={{ base: 0, md: 4 }}
            p={2}
            display={
              showMobileMessage
                ? { base: "none", lg: "none", md: "none" }
                : { base: "flow", lg: "none", md: "none" }
            }
          >
            <Button
              // type="submit"
              onClick={handleSubmit}
              colorScheme="blue"
              mr={3}
              id="apply_btn"
              isLoading={isSubmiited}
              loadingText="Submitting"
              isDisabled={isDisabled}
            >
              Submit
            </Button>

            <Button onClick={handleCancel}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
