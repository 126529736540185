import * as React from "react";
import Header from "./header";
import Footer from "./footer";
import SEOHead from "../SEO";

const Layout = ({ children }) => {
  return (
    <>
    <SEOHead
        title={"Sparkle Infotech - Your Top Rated Plus Outsourcing Solution"}
        description={
          "Sparkle Infotech delivers custom web development services worldwide. Our expert team helps businesses achieve goals with innovative digital solutions."
        }
        url={process.env.NEXT_PUBLIC_SITE_URL}
        twitterHandler={"discussionfora1"}
        imageUrl={`${process.env.NEXT_PUBLIC_SITE_URL}/uploads/sparkleinfotechseo_80a1ccf6b9.png`}
      />
      <Header siteTitle={`Title`} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
