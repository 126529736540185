import { IMAGE_FRAGMENT, BANNER_FRAGMENT } from "@/apollo/fragments";
import { gql } from "@apollo/client";

export const BLOG_BY_SLUG = gql`
  query BlogBySlug($filters: BlogFiltersInput) {
    blogs(filters: $filters) {
      data {
        attributes {
          Title
          slug
          Description
          SEO {
            pageTitle
            pageDescription
          }
          Image {
            ...Image
          }
          Contact_Us {
            ...Banner
          }
        }
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${BANNER_FRAGMENT}
`;
